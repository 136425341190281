import Swal from "sweetalert2";

export const ACCOUNT_ID = 3;
export var ACCOUNT_TAX = 0;
export var ACCOUNT_LOGO = '/static/media/logo-semfundo.864e31ffe4be43a5dfc7.png';
export const setLogo = (value) => {
    ACCOUNT_LOGO = value;
} 
export const setTax = (value) => {
    ACCOUNT_TAX = value;
} 

export const number_format = (number) => {

    var format = Intl.NumberFormat('PT-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(number)

    return format;

}

export const URL_API = "https://delivery.tw10.com.br/php/services";

export const POST_API = (url, data, img = null) => {

    function createFormData () {
        const form = new FormData()
        
        if (img != null) {
            img?.forEach((item, index) => {
                form.append('file[]', item.originFileObj);
            })
        }
    
        Object.keys(data).forEach(key => {
            form.append(key, data[key]);
        });
      
        return form;
    };
  
    return fetch(URL_API+url, { method: "post", body: createFormData() })

}

export const LOAD_CEP = (value) => {
    return fetch('https://viacep.com.br/ws/'+value+'/json/')
};

export const SMART_ALERT = async (text, title = 'Atenção!', confirmButtonText = 'Ok', showDenyButton = false, denyButtonText = 'Não') => {

    var data;
    await Swal.fire({
        html: `<img width="50%" src="${ACCOUNT_LOGO}" /> <h2 style="font-weight: 700; color: var(--primary)">${title}</h2> <h3>${text}</h3>`,
        confirmButtonText: confirmButtonText,
        confirmButtonColor: 'var(--primary)',
        showDenyButton: showDenyButton,
        denyButtonText: denyButtonText,
    }).then(rs => {
        data = rs
    })

    return data

}
